import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .async-select-with-callback {
    z-index: 10 !important;
  }
  #containerloja {
    div:first-child {
      z-index: 20 !important;
    }
  }
  .disabled {
    background: red;
  }
  #containe-busca-produto {
    padding: 0;
    margin: 0;
    button {
      width: 100%;
    }
  }

  .containerBuscarProduto {
    display: flex;

    .row {
      .col-md-8 {
        width: 80% !important;
      }
    }
  }

  .containerTable .table tbody tr td input {
    text-align: right;
  }

  .super-app-theme--header {
    background-color: #8850bf !important;
    color: #fff;
  }
  .super-app-theme--cell {
    text-align: center !important;
    align-items: center !important;
  }
  .table-input {
    th {
      text-align: right;
      font-weight: 400;
      max-width: 80px;
      background-color: red;
    }

    th:nth-child(3) {
      max-width: 80px;
      background-color: red;
    }

    .css-13fq05f {
      border: none;
      border-right: 1px solid #ccc;
      border-radius: 0px;
    }
  }
  .table tbody {
    border: 1px solid #dee2e6 !important;
  }
  .table tbody tr td {
    border-left: 1px solid #dee2e6;
  }
  .table tbody tr td input {
    width: 100%;
    height: 100%;
    border: none;
    box-sizing: border-box;
    &:disabled {
      border: none !important;
      background-color: transparent !important;
    }
  }
  .table thead tr th {
    padding-left: 0px;
    border: none;
    font-size: 0.8571428571428571em;
    font-style: inherit;
    color: #6b778c;
    font-weight: 600 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
  .isInvalid {
    border-bottom: #cf1919 solid 2px;
  }
  .qtdCaixa {
    font-size: 0.8571428571428571em;
    font-style: inherit;
    line-height: 1.3333333333333333;
    color: #6b778c;
    font-weight: 600;
    display: inline-block;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
  .cancelado {
    height: 38px;
    line-height: 38px;
    padding: 0px 10px 0px 10px;
    margin-top: 31px;
    color: #e63c3c;
    background-color: #e5e5e5;
    text-align: center;
    vertical-align: middle;
    h2 {
      margin-top: 5px;
      font-size: 20px;
      font-style: inherit;
      line-height: 1.3333333333333333;
      font-weight: 600;
      display: inline-block;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    }
  }
`;

export const ButtonCancel = styled.button`
  height: 40px;
  min-width: 85px;
  border-radius: 4px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 26.01px;
  animation: all 0.25s ease-in;
  margin: 0 5px;
  background-color: #dc3545;
  &:disabled {
    background-color: #e3bec1;
    cursor: not-allowed;
    &:hover {
      background-color: #e3bec1;
      opacity: 1;
    }
    // eslint-disable-next-line prettier/prettier
  }
  &:hover {
    opacity: 0.7;
  }
  box-shadow: transparent !important;
`;

export const ContainerLoader = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
