import { ProdutoProps } from '../types';

export const calculaQtdEst = (
  operacao: 'soma' | 'subtracao',
  produto: ProdutoProps,
  qtdAlteracao: number,
  tipoUnidadeAjuste: number,
): number => {
  const qtdEstAtual = Number(produto?.qtd_est_atual);
  const qtdEmbCompra = Number(produto?.qtd_embalagem_compra);

  let resultado = 0;

  if (tipoUnidadeAjuste === 0 || tipoUnidadeAjuste === 1) {
    const ajuste =
      tipoUnidadeAjuste === 1 ? qtdAlteracao * qtdEmbCompra : qtdAlteracao;

    if (operacao === 'soma') {
      resultado = qtdEstAtual + ajuste;
    } else if (operacao === 'subtracao') {
      resultado = qtdEstAtual - ajuste;
    }
  }

  return resultado;
};
