import { yupResolver } from '@hookform/resolvers/yup';
import { GridRowParams } from '@material-ui/data-grid';
import FormDefault from '~/components/FormDefault';
import InputSelect from '~/components/Inputs/InputSelect';
import InputText from '~/components/Inputs/InputText';
import Loja from '~/components/Loja';
import { BuscaProduto, InputAsyncSelect } from '~/components/NovosInputs';
import Search from '~/components/Search';
import Separator from '~/components/Separator';
import ToggleDefault from '~/components/ToggleDefault';
import useAuth from '~/hooks/useAuth';
import api from '~/services/api';
import { format } from 'date-fns';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { ThemeContext } from 'styled-components';

import { renderizarUnidade } from './utils/renderizarUnidade';
import { ButtonCancel, Container, ContainerLoader } from './styles';
import {
  FormDataProps,
  ProdutoProps,
  ProdutoResponseProps,
  TipoAjusteProps,
} from './types';
import { formataResultado } from './utils/formataResultado';
import { calculaQtdEst } from './utils/calculaQtdEst';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { schemaAjusteEstoque } from './validations';
import { moneyFormat } from '~/utils/functions';

const MySwal = withReactContent(Swal);

const AjusteDeEstoque: React.FC = () => {
  const user = useAuth();
  const { colors } = useContext(ThemeContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [isInvalidProduto, setIsInvalidProduto] = useState(false);
  const [selectTipoAjustes, setSelectTipoAjustes] = useState<TipoAjusteProps[]>(
    [],
  );
  const [isDisableSearch, setIsDisableSearch] = useState(false);
  const [produto, setProduto] = useState<ProdutoProps | any>(undefined);
  const [qtdAlteracao, setQtdAlteracao] = useState<number>();
  const [estPosterior, setEstPosterior] = useState<number>();
  const [enableTyping, setEnableTyping] = useState(false);
  const [tipoUnidadeAjuste, setTipoUnidadeAjuste] = useState<
    number | undefined
  >(undefined);

  const [iniInicializado, setIniInicializado] = useState(false);

  const [formData, setFormData] = useState<FormDataProps>({
    cod_seq_ajuste_estoque: {
      value: undefined,
      isInvalid: false,
      isRequired: false,
    },
    cod_loja: {
      value: user?.user?.loja,
      isInvalid: user?.user?.loja === undefined,
      isRequired: true,
    },
    cod_tipo_ajuste: {
      value: { label: undefined, value: undefined, tipo_operacao: undefined },
      isInvalid: true,
      isRequired: true,
    },
    num_documento: {
      value: '',
      isInvalid: false,
      isRequired: false,
    },
    qtd_ajuste: {
      value: undefined,
      isInvalid: false,
      isRequired: false,
    },
    qtd_estoque_post: {
      value: undefined,
      isInvalid: false,
      isRequired: false,
    },
    qtd_estoque_ant: {
      value: 0,
      isInvalid: false,
      isRequired: false,
    },
    flg_cancelado: {
      value: false,
      isInvalid: false,
      isRequired: false,
    },
    des_produto: {
      value: '',
      isInvalid: false,
      isRequired: false,
    },
  });

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [formDisabled, setFormDisabled] = useState({
    loja: false,
    produto: true,
  });
  const [cancelaPesquisa, setCancelaPesquisa] = useState(false);
  const [contadorClear, setContadorClear] = useState(0);

  const buscaPrdutoRef = useRef<HTMLDivElement | null>(null);
  const ajusteRef = useRef<HTMLDivElement | null>(null);

  const {
    handleSubmit,
    register,
    control,
    getValues,
    clearErrors,
    watch,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    // reValidateMode: 'onSubmit',
    resolver: yupResolver(schemaAjusteEstoque),
  });

  const watchManterDados = watch('manterDados');
  const watchManterProduto = watch('manterProduto');

  useEffect(() => {
    if (user?.user?.loja !== undefined && !isUpdate) {
      setFormData({
        ...formData,
        cod_loja: {
          ...formData.cod_loja,
          value: user?.user?.loja,
          isInvalid: false,
        },
      });
    }
  }, [user?.user?.loja]);

  const validaInputsParaSubmit = useCallback(
    (inputs: FormDataProps): boolean => {
      for (let i = 0; i < Object.keys(inputs).length; i++) {
        if (
          (formData[Object.keys(inputs)[i] as keyof FormDataProps].isRequired &&
            formData[Object.keys(inputs)[i] as keyof FormDataProps]
              .isInvalid) ||
          (formData[Object.keys(inputs)[i] as keyof FormDataProps]
            .isRequired === false &&
            formData[Object.keys(inputs)[i] as keyof FormDataProps].isInvalid)
        ) {
          return true;
        }
      }
      return false;
    },
    [formData],
  );

  const focus = async (idOrName: string) => {
    let input = document.getElementById(idOrName);
    if (!input) input = document.querySelector(`[name="${idOrName}"]`);
    input?.focus();
  };

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    setUpdate(true);
    setIsDisabled(true);
    const {
      cod_seq_ajuste_estoque,
      cod_produto,
      cod_loja,
      tipo_operacao,
      tipo_operacao_view,
      cod_tipo_ajuste,
      qtd_ajuste,
      num_documento,
      qtd_estoque_ant,
      qtd_estoque_post,
      flg_cancelado,
      des_produto,
    } = row;
    setValue('num_documento', num_documento);
    setValue('ajuste', {
      value: tipo_operacao,
      label: `${cod_tipo_ajuste} - ${tipo_operacao_view}`,
      tipo_operacao: undefined,
    });
    setFormData({
      cod_seq_ajuste_estoque: {
        value: cod_seq_ajuste_estoque,
        isInvalid: false,
        isRequired: false,
      },
      cod_loja: {
        value: cod_loja,
        isInvalid: false,
        isRequired: false,
      },
      cod_tipo_ajuste: {
        value: {
          value: tipo_operacao,
          label: `${cod_tipo_ajuste} - ${tipo_operacao_view}`,
          tipo_operacao: undefined,
        },
        isInvalid: false,
        isRequired: false,
      },
      num_documento: {
        value: num_documento,
        isInvalid: false,
        isRequired: false,
      },
      qtd_ajuste: {
        value: undefined,
        isInvalid: false,
        isRequired: false,
      },
      qtd_estoque_post: {
        value: qtd_estoque_post,
        isInvalid: false,
        isRequired: false,
      },
      qtd_estoque_ant: {
        value: qtd_estoque_ant,
        isInvalid: false,
        isRequired: false,
      },
      flg_cancelado: {
        value: flg_cancelado,
        isInvalid: false,
        isRequired: false,
      },
      des_produto: {
        value: des_produto,
        isInvalid: false,
        isRequired: false,
      },
    });
    setFormDisabled({
      loja: true,
      produto: true,
    });
    // setLoja({ value: cod_loja, label: des_loja });
    // setAjuste({ value: cod_tipo_ajuste, label: des_operacao_ajuste });
    const { data } = await api.get<ProdutoResponseProps>('/returnprodutos', {
      params: {
        tipo_busca: 0,
        filtro: [{ cod_produto }],
        cod_loja,
        inativo: true,
      },
    });

    if (data.success) {
      setProduto(data.data[0]);
      setTipoUnidadeAjuste(
        selectTipoAjustes.find((tp) => tp.cod_tipo_ajuste === cod_tipo_ajuste)
          ?.tipo_unidade ?? undefined,
      );
    }
    if (!data.data[0]?.flg_ipv) {
      setValue('qtd_alteracao', Math.trunc(qtd_ajuste));
      setQtdAlteracao(Math.trunc(qtd_ajuste));
      setEstPosterior(Math.trunc(qtd_estoque_post));
    } else {
      setValue('qtd_alteracao', qtd_ajuste);
      setQtdAlteracao(qtd_ajuste);
      setEstPosterior(qtd_estoque_post);
    }

    setLoader(false);

    setShowSearch(false);
  };
  const resetFormData = async () => {
    setIniInicializado(false);
    const { manterDados, manterProduto } = getValues();
    const produtoEstPosterior = produto;
    if (manterProduto && !manterDados) {
      produtoEstPosterior.qtd_est_atual = estPosterior;
      setProduto(produtoEstPosterior);
      setIniInicializado(false);
      setFormData({
        ...formData,
        cod_tipo_ajuste: {
          value: {
            label: undefined,
            value: undefined,
            tipo_operacao: undefined,
          },
          isInvalid: true,
          isRequired: true,
        },
        num_documento: {
          value: '',
          isInvalid: false,
          isRequired: false,
        },
      });
      setIsDisabled(true);
      setQtdAlteracao(undefined);
      setEstPosterior(undefined);
      setValue('ajuste', '');

      setTimeout(() => {
        focusOnNextField();
      }, 255);

      await focus('ajuste');
      return;
    }
    if (!manterProduto && manterDados) {
      setFormData({
        ...formData,
        cod_loja: {
          value: user.user?.loja,
          isInvalid: user.user?.loja !== undefined,
          isRequired: true,
        },
      });
      setFormDisabled({
        ...formDisabled,
        loja: false,
        produto: true,
      });
      setIsDisabled(false);
      setProduto(undefined);
      setIsDisableSearch(false);
      setValue('produtos', { value: undefined, label: '' });
      clearErrors('qtd_alteracao');
      setValue('qtd_alteracao', undefined);
      setQtdAlteracao(undefined);
      setEstPosterior(undefined);
      await focus('input-produto');
      return;
    }
    if (!manterProduto && !manterDados) {
      setFormData({
        ...formData,
        cod_loja: {
          value: user.user?.loja,
          isInvalid: user.user?.loja === undefined,
          isRequired: true,
        },
        cod_tipo_ajuste: {
          value: {
            label: undefined,
            value: undefined,
            tipo_operacao: undefined,
          },
          isInvalid: true,
          isRequired: true,
        },
        num_documento: {
          value: '',
          isInvalid: false,
          isRequired: false,
        },
      });
      setFormDisabled({
        ...formDisabled,
        loja: false,
        produto: true,
      });
      setIsDisabled(true);
      setValue('qtd_alteracao', undefined);
      setQtdAlteracao(undefined);
      setEstPosterior(undefined);
      setProduto(undefined);
      // setShowSearch(true);
      setIsDisableSearch(false);
      reset();
      setValue('manterDados', false);
      setValue('manterproduto', false);

      setValue('produtos', { value: undefined, label: '' });
      await focus('input-produto');
      return;
    }
    if (manterProduto && manterDados) {
      produtoEstPosterior.qtd_est_atual = estPosterior;
      setProduto(produtoEstPosterior);
      setQtdAlteracao(0);
      // setEstPosterior(undefined);
      setTimeout(() => {
        focus('qtd_alteracao');
      }, 255);
      return;
    }
    setValue('ajuste', '');
    setValue('qtd_alteracao', undefined);
    setQtdAlteracao(undefined);
    setEstPosterior(undefined);
    setEnableTyping(false);
    setTipoUnidadeAjuste(undefined);
  };

  const resetAllFormData = () => {
    setFormData({
      cod_seq_ajuste_estoque: {
        value: undefined,
        isInvalid: false,
        isRequired: false,
      },
      cod_loja: {
        value: user.user?.loja,
        isInvalid: user.user?.loja === undefined,
        isRequired: true,
      },
      cod_tipo_ajuste: {
        value: { label: undefined, value: undefined, tipo_operacao: undefined },
        isInvalid: true,
        isRequired: true,
      },
      num_documento: {
        value: '',
        isInvalid: false,
        isRequired: false,
      },
      qtd_ajuste: {
        value: undefined,
        isInvalid: false,
        isRequired: false,
      },
      qtd_estoque_post: {
        value: undefined,
        isInvalid: false,
        isRequired: false,
      },
      qtd_estoque_ant: {
        value: 0,
        isInvalid: false,
        isRequired: false,
      },
      flg_cancelado: {
        value: false,
        isInvalid: false,
        isRequired: false,
      },
      des_produto: {
        value: undefined,
        isInvalid: false,
        isRequired: false,
      },
    });
    setFormDisabled({
      ...formDisabled,
      loja: false,
      produto: true,
    });
    setProduto(undefined);
    setValue('ajuste', '');
    setValue('manterDados', false);
    setValue('manterProduto', false);
    setValue('qtd_alteracao', undefined);
    setQtdAlteracao(undefined);
    setEstPosterior(undefined);
    setIniInicializado(false);
    setIsInvalidProduto(false);
    setIsDisableSearch(false);
    setIsDisabled(true);
    setEnableTyping(false);
    setTipoUnidadeAjuste(undefined);
  };

  const handleLoja = useCallback(
    (value: number, isInvalid: boolean) => {
      setFormData({
        ...formData,
        cod_loja: { ...formData.cod_loja, value, isInvalid },
      });
      setFormDisabled({
        ...formDisabled,
        produto: false,
      });
    },
    [formData, formDisabled],
  );
  const handleTipoAjuste = useCallback(
    (tipoAjuste: TipoAjusteProps, isInvalid: boolean) => {
      const val = {
        label: tipoAjuste.label,
        value: tipoAjuste.value,
        tipo_operacao: tipoAjuste.tipo_operacao,
      };

      setIsDisabled(false);
      if (!produto?.flg_ipv) {
        setEstPosterior(Math.trunc(produto?.qtd_est_atual));
      } else {
        setEstPosterior(produto?.qtd_est_atual);
      }
      setValue('cod_tipo_ajuste', val);
      setFormData({
        ...formData,
        cod_tipo_ajuste: { ...formData.cod_tipo_ajuste, value: val, isInvalid },
      });
      setValue('qtd_alteracao', 0);
      setQtdAlteracao(0);
      if (!produto?.flg_ipv) {
        setEstPosterior(Math.trunc(produto?.qtd_est_atual));
        return;
      }
      setEstPosterior(produto?.qtd_est_atual);
    },
    [formData, produto, setValue],
  );
  const handleNumDocumento = useCallback(
    (val: string, isInvalid: boolean) => {
      setValue('num_documento', val);
      setFormData({
        ...formData,
        num_documento: {
          ...formData.num_documento,
          value: val,
          isInvalid,
        },
      });
    },
    [formData, setValue],
  );

  const focusOnNextField = () => {
    if (ajusteRef.current) {
      const inputAjuste: HTMLInputElement | null =
        ajusteRef.current.querySelector('[id="ajuste"]');
      if (inputAjuste && inputAjuste.value.length <= 0) {
        inputAjuste.focus();
        inputAjuste.click();
      } else if (inputAjuste && inputAjuste.value.length > 0) {
        focus('qtd_alteracao');
      }
    }
  };

  const handleAddProduto = async (prod: any[]) => {
    const { manterDados } = getValues();

    if (prod.length === 0) {
      return toast.warning('Nenhum produto atende o critério de busca');
    }
    setProduto(prod[0]);

    setValue('produtos', {
      value: prod[0].cod_produto,
      label: `${prod[0].cod_produto} - ${
        prod[0].des_produto
      } | Estq.: ${moneyFormat(prod[0].qtd_total_estoque.toString(), 3, 3)} |`,
    });
    setFormDisabled({
      ...formDisabled,
      loja: true,
      produto: true,
    });
    setIsInvalidProduto(false);
    if (prod.length > 0) {
      setIsDisableSearch(true);
    }

    const product = prod[0];
    const isAssociado =
      product.cod_associado !== null &&
      typeof product.cod_associado === 'string';

    const handleConfirmation = async () => {
      const prodctAssociado = await api.get(
        `/produto/busca-pai/${product.cod_associado}/${product.cod_loja}`,
      );

      const prodAssociado = prodctAssociado.data;

      if (manterDados) {
        setQtdAlteracao(0);
        setEstPosterior(prodAssociado.qtd_est_atual);
      }

      setProduto(prodAssociado);
      setFormDisabled({
        ...formDisabled,
        loja: true,
        produto: true,
      });
      setIsInvalidProduto(false);

      if (prodAssociado !== null) setIsDisableSearch(true);
    };

    if (isAssociado) {
      await MySwal.fire({
        title: 'Confirmação',
        text: 'Não é permitido fazer Ajuste de Estoque em Item Associado. Deseja pesquisar o produto pai?',
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: '#28A745',
        cancelButtonColor: '#DC3545',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        icon: 'question',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await handleConfirmation();

          setTimeout(() => {
            focusOnNextField();
          }, 255);
        } else {
          resetAllFormData();
          setUpdate(false);
          setShowSearch(false);

          setTimeout(() => {
            if (!showSearch && !isUpdate && buscaPrdutoRef.current) {
              const inputBuscaProduto: HTMLInputElement | null =
                buscaPrdutoRef.current.querySelector('[name="produtos"]');

              if (inputBuscaProduto) {
                inputBuscaProduto.focus();
                inputBuscaProduto.click();
              }
            }
          }, 255);
        }
      });
    } else {
      setProduto(product);
      setFormDisabled({
        ...formDisabled,
        loja: true,
        produto: true,
      });
      setIsInvalidProduto(false);
      if (prod.length > 0) {
        setIsDisableSearch(true);
      }

      if (manterDados) {
        setQtdAlteracao(0);
        setEstPosterior(product.qtd_est_atual);
      }

      if (formData.cod_tipo_ajuste.value === undefined) {
        focus('ajuste');
      } else {
        focus('qtd_alteracao');
      }
    }
  };

  const handleDeleteProduto = async () => {
    const { manterDados } = getValues();
    setCancelaPesquisa(true);
    setProduto(undefined);
    setIsDisableSearch(false);
    setIsDisabled(true);
    setFormDisabled({
      ...formDisabled,
      loja: false,
      produto: false,
    });
    setIniInicializado(false);
    setValue('produtos', {
      value: '',
      label: '',
    });
    if (!manterDados) {
      setValue('num_documento', '');
      setFormData({
        ...formData,
        cod_tipo_ajuste: {
          value: {
            label: undefined,
            value: undefined,
            tipo_operacao: undefined,
          },
          isInvalid: true,
          isRequired: true,
        },
        num_documento: {
          value: '',
          isInvalid: false,
          isRequired: false,
        },
      });
    }
    setValue('qtd_alteracao', undefined);
    setQtdAlteracao(undefined);
    setEstPosterior(undefined);
    setEnableTyping(false);
    setTipoUnidadeAjuste(undefined);

    await focus('input-produto');
  };

  const validaQuantidadeAlteracaoInformada = (
    quantidadeAlteracao: number,
    tipoAjuste: number | undefined,
  ) => {
    if (
      tipoAjuste !== undefined &&
      quantidadeAlteracao > 0 &&
      typeof quantidadeAlteracao === 'number'
    )
      return true;
  };

  const calculaQuantidadeEstoquePosteriorPorAlteracao = useCallback(
    (quantidadeAlteracao: number, tipoAjuste: number | undefined) => {
      if (tipoAjuste === 0) {
        const soma = calculaQtdEst(
          'soma',
          produto,
          quantidadeAlteracao,
          Number(tipoUnidadeAjuste),
        );
        const resultadoFormatado = formataResultado(soma);
        const resultadoSemMilhar = resultadoFormatado.replace(/\./g, '');

        // Converte a string formatada de volta para um número
        const resultadoNumerico = parseFloat(
          resultadoSemMilhar.replace(',', '.'),
        );
        setEstPosterior(resultadoNumerico);
      } else {
        const subtracao = calculaQtdEst(
          'subtracao',
          produto,
          quantidadeAlteracao,
          Number(tipoUnidadeAjuste),
        );
        const resultadoFormatado = formataResultado(subtracao);
        const resultadoSemMilhar = resultadoFormatado.replace(/\./g, '');
        // Converte a string formatada de volta para um número
        const resultadoNumerico = parseFloat(
          resultadoSemMilhar.replace(',', '.'),
        );
        setEstPosterior(resultadoNumerico);
      }
    },
    [produto, tipoUnidadeAjuste],
  );

  const handleQtdAlteracao = useCallback(
    (val: number) => {
      const quantidadeAlteracao: number = val;
      if (
        validaQuantidadeAlteracaoInformada(
          quantidadeAlteracao,
          formData.cod_tipo_ajuste.value.tipo_operacao,
        )
      ) {
        setValue('qtd_alteracao', quantidadeAlteracao);
        setQtdAlteracao(quantidadeAlteracao);
        calculaQuantidadeEstoquePosteriorPorAlteracao(
          quantidadeAlteracao,
          formData.cod_tipo_ajuste.value.tipo_operacao,
        );

        return;
      }
      setValue('qtd_alteracao', 0);
      setQtdAlteracao(0);
      setEstPosterior(produto?.qtd_est_atual);
    },
    [
      formData.cod_tipo_ajuste.value.tipo_operacao,
      calculaQuantidadeEstoquePosteriorPorAlteracao,
      produto,
      setValue,
    ],
  );

  const validaQuantidadePosteriorInformada = useCallback(
    (estoquePosterior: number, tipoAjuste: number | undefined) => {
      if (tipoAjuste !== undefined) {
        switch (tipoAjuste) {
          case 0: // Operação positiva
            if (
              estoquePosterior >= Number(produto?.qtd_est_atual) &&
              typeof estoquePosterior === 'number'
            ) {
              return true;
            }
            setEstPosterior(
              enableTyping ? estoquePosterior : Number(produto?.qtd_est_atual),
            );
            setValue('qtd_alteracao', 0);
            setQtdAlteracao(0);
            return false;
          case 1: // Operação Negaiva
            if (
              estoquePosterior <= Number(produto?.qtd_est_atual) &&
              typeof estoquePosterior === 'number'
            ) {
              return true;
            }
            setEstPosterior(
              enableTyping ? estoquePosterior : Number(produto?.qtd_est_atual),
            );
            setValue('qtd_alteracao', 0);
            setQtdAlteracao(0);
            return false;
          default:
            return false;
        }
      }
      setEstPosterior(0);
      setValue('qtd_alteracao', 0);
      setQtdAlteracao(0);
      return false;
    },
    [produto?.qtd_est_atual, enableTyping],
  );

  const calculaQuantidadeAlteracaoPorEstoquePosterior = useCallback(
    (estoquePosterior: number, tipoAjuste: number | undefined) => {
      if (tipoAjuste === 0) {
        const quantidadeAlteracao =
          estoquePosterior - Number(produto.qtd_est_atual);
        const resultadoFormatado = formataResultado(quantidadeAlteracao);
        const resultadoSemMilhar = resultadoFormatado.replace(/\./g, '');

        // Converte a string formatada de volta para um número
        const resultadoNumerico = parseFloat(
          resultadoSemMilhar.replace(',', '.'),
        );
        setValue('qtd_alteracao', resultadoNumerico);
        setQtdAlteracao(resultadoNumerico);
      } else {
        const quantidadeAlteracao =
          Number(produto?.qtd_est_atual) - estoquePosterior;
        const resultadoFormatado = formataResultado(quantidadeAlteracao);
        const resultadoSemMilhar = resultadoFormatado.replace(/\./g, '');

        // Converte a string formatada de volta para um número
        const resultadoNumerico = parseFloat(
          resultadoSemMilhar.replace(',', '.'),
        );
        setValue('qtd_alteracao', resultadoNumerico);
        setQtdAlteracao(resultadoNumerico);
      }
    },
    [produto?.qtd_est_atual],
  );

  const handleEstoquePosterior = useCallback(
    (estoquePosterior: number) => {
      // if (!produto.flg_ipv) {
      //   setEstPosterior(Math.trunc(estoquePosterior));
      // } else {
      //   setEstPosterior(estoquePosterior);
      // }
      if (
        validaQuantidadePosteriorInformada(
          estoquePosterior,
          formData.cod_tipo_ajuste.value.tipo_operacao,
        )
      ) {
        setEstPosterior(estoquePosterior);

        calculaQuantidadeAlteracaoPorEstoquePosterior(
          estoquePosterior,
          formData.cod_tipo_ajuste.value.tipo_operacao,
        );
      }
    },
    [
      formData.cod_tipo_ajuste.value.tipo_operacao,
      calculaQuantidadeAlteracaoPorEstoquePosterior,
      validaQuantidadePosteriorInformada,
    ],
  );

  function formatNumber(value: number | undefined | null) {
    if (value === undefined || value === null) {
      return '';
    }
    return formataResultado(value);
  }

  function isFloat(value: any) {
    return Number(value) === value && value % 1 !== 0;
  }

  const onSave = handleSubmit(async (dataForm) => {
    try {
      if (validaInputsParaSubmit(formData) || produto === undefined) {
        setIniInicializado(true);
        setIsInvalidProduto(false);
        if (produto === undefined) {
          setIsInvalidProduto(true);
        }
        return;
      }
      if (
        (dataForm.qtd_alteracao !== undefined && dataForm.qtd_alteracao <= 0) ||
        dataForm.qtd_alteracao === undefined
      ) {
        setIniInicializado(true);
        await focus('qtd_alteracao');
        return;
      }
      if (!produto?.flg_ipv && isFloat(dataForm.qtd_alteracao)) {
        toast.warn('Quantidade ajuste não pode ser fracionado');
        return;
      }

      setLoader(true);
      const { data } = await api.post('/ajuste', {
        cod_produto: produto.cod_produto,
        cod_loja: formData.cod_loja.value,
        cod_tipo_ajuste: formData.cod_tipo_ajuste.value.value,
        qtd_ajuste: dataForm.qtd_alteracao,
        num_documento: dataForm.num_documento,
        qtd_estoque_ant:
          produto.qtd_est_atual !== null ? produto.qtd_est_atual : 0,
        qtd_estoque_post: estPosterior,
        dta_ajuste: format(new Date(), 'yyy-MM-dd'),
        val_custo_rep: produto?.val_custo_rep,
      });
      if (data.success) {
        toast.success(data.message);
        await resetFormData();
      }
    } finally {
      setLoader(false);
    }
  });

  const handleCancelarAjuste = async () => {
    if (formData.flg_cancelado.value) {
      return toast.warning('Ajuste já cancelado.');
    }

    const { data } = await api.delete(
      `/ajuste/${formData?.cod_seq_ajuste_estoque.value}`,
    );
    const { message, success } = data;
    // setFormData((prev: any) => ({ ...prev, flg_cancelado: true }));
    setFormData({
      ...formData,
      flg_cancelado: {
        ...formData.flg_cancelado,
        value: true,
      },
    });
    if (success) {
      toast.success(message);
    }
  };

  useEffect(() => {
    if (!showSearch && !isUpdate && buscaPrdutoRef.current) {
      const inputBuscaProduto: HTMLInputElement | null =
        buscaPrdutoRef.current.querySelector('[name="produtos"]');

      if (inputBuscaProduto) {
        inputBuscaProduto.focus();
        inputBuscaProduto.click();
      }
    }
  }, [isUpdate, showSearch]);

  useEffect(() => {
    if (!showSearch && !isUpdate && buscaPrdutoRef.current) {
      const inputBuscaProduto: HTMLInputElement | null =
        buscaPrdutoRef.current.querySelector('[name="produtos"]');

      if (inputBuscaProduto) {
        inputBuscaProduto.focus();
        inputBuscaProduto.click();
      }
    }
  }, [isUpdate, showSearch]);

  useEffect(() => {
    if (produto && ajusteRef.current) {
      const inputAjuste: HTMLInputElement | null =
        ajusteRef.current.querySelector('[id="ajuste"]');

      if (inputAjuste && inputAjuste.value.length <= 0) {
        inputAjuste.focus();
        inputAjuste.click();
      } else if (inputAjuste && inputAjuste.value.length > 0) {
        focus('qtd_alteracao');
      }
    }
  }, [produto]);

  useEffect(() => {
    if (cancelaPesquisa && buscaPrdutoRef.current) {
      const inputBuscaProduto: HTMLInputElement | null =
        buscaPrdutoRef.current.querySelector('[name="produtos"]');

      if (inputBuscaProduto) {
        inputBuscaProduto.focus();
        inputBuscaProduto.click();
      }
    }
  }, [cancelaPesquisa, contadorClear]);

  useEffect(() => {
    if (buscaPrdutoRef.current) {
      const inputBuscaProduto: HTMLInputElement | null =
        buscaPrdutoRef.current.querySelector('[name="produtos"]');

      if (inputBuscaProduto && inputBuscaProduto.value.length <= 0) {
        inputBuscaProduto.focus();
        inputBuscaProduto.click();
      }
    }
  }, [contadorClear, loader]);

  if (loader) {
    return (
      <ContainerLoader>
        <ClipLoader color={colors.primary} />
      </ContainerLoader>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          codTela={30}
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
        />
      )}

      {!showSearch && (
        <>
          <FormDefault
            codTela={30}
            title="Ajuste de Estoque"
            onSave={async () => {
              await onSave();
              setContadorClear((prev) => prev + 1);
            }}
            codigoRegistro={[
              {
                des_campo: 'Código do Ajuste',
                value: Number(formData.cod_seq_ajuste_estoque.value),
              },
            ]}
            onCancel={() => {
              setValue('manterDados', false);
              setValue('manterProduto', false);

              resetFormData();
              resetAllFormData();
              setShowSearch(true);
              setCancelaPesquisa(false);
            }}
            isUpdate={isUpdate}
            onNew={() => {
              resetAllFormData();
              setUpdate(false);
              setShowSearch(false);
            }}
            onDelete={handleCancelarAjuste}
            onClearFields={() => {
              resetAllFormData();
              setValue('produtos', {
                label: '',
                value: '',
              });
              setCancelaPesquisa(true);

              setContadorClear((prev) => prev + 1);
            }}
            onReturnSearch={() => {
              setShowSearch(true);
              setUpdate(false);
              resetAllFormData();
            }}
            labelButtonTrash="Cancelar Ajuste"
            deleteMessage="Deseja realmente cancelar o registro?"
            isDeactivateDeleteMessage={formData.flg_cancelado.value}
            isSave={!!isUpdate}
          >
            <div className="row" style={{ marginBottom: '20px' }}>
              <div id="containerloja" className="col-sm-12">
                <Loja
                  selectedLoja={formData.cod_loja.value}
                  IsInvalid={false}
                  isDisabled={formDisabled.loja}
                  onChange={(value: number) => {
                    handleLoja(value, value === undefined);
                  }}
                />
              </div>
              {!isUpdate && (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ width: '100%' }} ref={buscaPrdutoRef}>
                    <BuscaProduto
                      label="Buscar Produto"
                      placeholder="Selecione..."
                      name="produtos"
                      register={register}
                      isError={!!errors.produtos}
                      disabled={isDisableSearch || isUpdate}
                      control={control}
                      buscaNasLojas={[Number(formData.cod_loja.value)]}
                      customOptions={{
                        hideBuscarPor: true,
                        buscarPor: ['Produto'],
                        showSelecionarItensContendo: false,
                      }}
                      getProduct={(selected: any) => {
                        setValue('produtos', {
                          value: selected[0].cod_produto,
                          label: `${
                            selected[0].des_produto
                          } | Estq.: ${moneyFormat(
                            selected[0].qtd_total_estoque.toString(),
                            3,
                            3,
                          )} |`,
                        });
                        handleAddProduto(selected);
                        setCancelaPesquisa(false);
                      }}
                      style={{ width: '100%' }}
                      setValue={setValue}
                    />
                  </div>
                  <div>
                    <ButtonCancel
                      type="button"
                      disabled={!isDisableSearch}
                      onClick={() => handleDeleteProduto()}
                      style={{ marginTop: '1.875rem' }}
                    >
                      Cancelar
                    </ButtonCancel>
                  </div>
                </div>
              )}
              <div className="col-10 col-md-10">
                <table
                  className={`table  ${isInvalidProduto ? 'isInvalid' : ''}`}
                >
                  <thead>
                    <tr>
                      <th>Código</th>
                      <th>Descrição</th>
                      {/* qtd_estoque_ant */}
                      {!isUpdate ? <th>Est. Atual</th> : <th>Est. Anterior</th>}
                      {/* <th>Est Atual</th> */}
                      <th>Unid. Venda</th>
                      <th>Unid. Compra</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{produto?.cod_produto}</td>
                      <td>
                        <input
                          type="text"
                          disabled
                          value={
                            isDisableSearch && produto?.des_produto
                              ? produto.des_produto
                              : formData.des_produto.value || ''
                          }
                        />
                      </td>
                      {!isUpdate && isDisableSearch && (
                        <td>
                          {!produto?.flg_ipv
                            ? formatNumber(Math.trunc(produto?.qtd_est_atual))
                            : formatNumber(produto?.qtd_est_atual)}
                        </td>
                      )}
                      {isUpdate && !isDisableSearch && (
                        <td style={{ maxWidth: '80px' }}>
                          {!produto?.flg_ipv
                            ? formatNumber(formData.qtd_estoque_ant.value)
                            : formatNumber(formData.qtd_estoque_ant.value)}
                        </td>
                      )}
                      <td style={{ maxWidth: '80px' }}>
                        {renderizarUnidade(
                          produto,
                          'des_unidade_venda',
                          'qtd_embalagem_venda',
                        )}
                      </td>
                      <td>
                        {renderizarUnidade(
                          produto,
                          'des_unidade_compra',
                          'qtd_embalagem_compra',
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {!isUpdate && (
                <div className="col-sm-2 col-md-2">
                  <ToggleDefault
                    labelText="Manter Produto?"
                    onSwitch={() =>
                      setValue('manterProduto', !watchManterProduto)
                    }
                    setChecked={watchManterProduto}
                  />
                </div>
              )}
              <div className="col-12">
                <Separator labelText="Dados do Ajuste" />
              </div>
              <div className="col-sm-12 col-md-7" ref={ajusteRef}>
                <InputAsyncSelect
                  label="Tipo de Ajuste"
                  placeholder="Selecione o tipo de ajuste"
                  name="ajuste"
                  id="ajuste"
                  register={register}
                  isError={!!errors.ajuste}
                  control={control}
                  disabled={isUpdate}
                  changeSelected={(selected: any, isInvalid = true) => {
                    handleTipoAjuste(selected, false);
                    setTipoUnidadeAjuste(selected.tipo_unidade);
                    focus('qtd_alteracao');
                    setValue('ajuste', selected);
                  }}
                  api={{
                    route: '/ajuste',
                    method: 'get',
                    fields: ['cod_tipo_ajuste', 'des_operacao', 'des_ajuste'],
                    searchBeforeFilter: true,
                  }}
                  maxLength={50}
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <InputText
                  label="Docto"
                  value={formData.num_documento.value}
                  maxLength={45}
                  placeholder="Informe o número do documento"
                  isEmpty
                  isNull
                  isUndefined
                  isDisabled={isUpdate}
                  isRequired={formData.num_documento.isRequired}
                  setInvalid={formData.num_documento.isInvalid}
                  iniInicializado={iniInicializado}
                  onChange={(newValue: string, isInvalid = true) => {
                    handleNumDocumento(newValue, isInvalid);
                  }}
                />
              </div>
              {!isUpdate && (
                <div
                  className="col-sm-12 col-md-1 text-nowrap"
                  style={{ marginTop: '10px' }}
                >
                  <ToggleDefault
                    labelText="Manter Dados?"
                    onSwitch={() => setValue('manterDados', !watchManterDados)}
                    setChecked={watchManterDados}
                  />
                </div>
              )}
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="containerTable">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="pb-1">Quantidade Alteração</th>
                        <th className="ps-1 pb-1">Estoque Posterior</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ height: '36px' }}>
                        <td
                          style={{
                            // eslint-disable-next-line no-extra-boolean-cast
                            borderBottom: !!errors.qtd_alteracao
                              ? '2px solid  #cf1919'
                              : '1px solid  #dee2e6',
                          }}
                        >
                          {produto && (
                            <input
                              type="number"
                              step={
                                produto.des_unidade_compra === 'UN'
                                  ? '1'
                                  : '.01'
                              }
                              min={1}
                              id="qtd_alteracao"
                              value={qtdAlteracao}
                              onChange={(val) => {
                                const { value } = val.target;
                                clearErrors('qtd_alteracao');

                                // VERIFICA O TIPO DO PRODUTO PARA PERMITIR QUANTIDADE FRACIONADA OU NÃO
                                if (
                                  produto &&
                                  produto.des_unidade_compra === 'UN'
                                ) {
                                  if (Number.isInteger(Number(value))) {
                                    handleQtdAlteracao(Number(value));
                                  }
                                } else {
                                  if (value.includes('.')) {
                                    const partes = value.split('.');
                                    if (partes[1].length > 3) {
                                      partes[1] = partes[1].substring(0, 3);
                                      const valorLimitado = parseFloat(
                                        partes.join('.'),
                                      );
                                      handleQtdAlteracao(valorLimitado);
                                      return;
                                    }
                                  }

                                  handleQtdAlteracao(parseFloat(value));
                                }
                              }}
                              onFocus={(event) => event.target.select()}
                              disabled={isDisabled}
                            />
                          )}
                        </td>
                        <td>
                          {produto && (
                            <input
                              type="number"
                              step=".01"
                              min={0}
                              max={1000000000}
                              disabled={isDisabled}
                              aria-disabled="true"
                              value={estPosterior}
                              onChange={(val) => {
                                const { value } = val.target;

                                if (value.includes('.')) {
                                  const partes = value.split('.');
                                  if (partes[1].length > 3) {
                                    partes[1] = partes[1].substring(0, 3);
                                    const valorLimitado = parseFloat(
                                      partes.join('.'),
                                    );
                                    handleEstoquePosterior(valorLimitado);
                                    return;
                                  }
                                }
                                handleEstoquePosterior(parseFloat(value));
                              }}
                              onFocus={(event) => {
                                event.target.select();
                                setEnableTyping(true);
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                className="col-sm-12 col-md-2"
                style={{
                  paddingTop: '45px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <p
                  className="qtdCaixa"
                  style={{
                    fontSize: '20px',
                    marginTop: '-18px',
                    marginLeft: '-12px',
                    paddingBottom: '0px',
                  }}
                >
                  {isDisableSearch &&
                    tipoUnidadeAjuste !== undefined &&
                    renderizarUnidade(
                      produto,
                      tipoUnidadeAjuste === 0
                        ? 'des_unidade_venda'
                        : 'des_unidade_compra',
                      tipoUnidadeAjuste === 0
                        ? 'qtd_embalagem_venda'
                        : 'qtd_embalagem_compra',
                    )}
                </p>
              </div>
              {formData.flg_cancelado.value && (
                <div className="col-sm-12 col-md-2 cancelado">
                  <h2>CANCELADO</h2>
                </div>
              )}
            </div>
          </FormDefault>
        </>
      )}
    </Container>
  );
};

export default AjusteDeEstoque;
