import { ProdutoProps } from '../types';

export const renderizarUnidade = (
  produto: ProdutoProps,
  desProp: 'des_unidade_venda' | 'des_unidade_compra',
  qtdProp: 'qtd_embalagem_venda' | 'qtd_embalagem_compra',
): string => {
  if (!produto) return '';

  const value = `${produto[desProp]} C/ ${
    produto[desProp] !== 'KG'
      ? `${Math.trunc(produto[qtdProp])}`
      : `${produto[qtdProp]}`
  }`;

  return value;
};
