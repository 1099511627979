import * as yup from 'yup';
import { transformAsCurrency } from '~/utils/functions';

export const schemaAjusteEstoque = yup
  .object({
    produtos: yup.object().shape({
      value: yup.string().required(),
      label: yup.string().required(),
    }),
    qtd_alteracao: yup
      .string()
      .required()
      .test((val) => {
        const valueToFloat = transformAsCurrency(val || 0);
        return valueToFloat !== 0;
      }),
  })
  .required();
